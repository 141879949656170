  .title {
    /* Text */
    color: white;
    font-size: 64px;
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .description {
    width: 75%;
    color: white;
    font-size: 20px;
    background-color: #0b0d2b;
  }

  .text {
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
  }

  .inputBox {
    font-size: 20px;
    margin-right: 10px;
  }

  .button-approve {
    color: white;
    font-size: 22px;
    background-color: #017BFF;
    padding: 3px 3px;
    border-radius: 6px;
    margin-bottom: 30px;
  }

  .button-add {
    float: right;
    color: white;
    font-size: 22px;
    background-color: #017BFF;
    padding: 3px 3px;
    border-radius: 6px;
    margin-left: 3px;
  }

  .button-remove {
    color: white;
    font-size: 22px;
    background-color: #017BFF;
    padding: 3px 3px;
    border-radius: 6px;
    margin-left: 10px;
  }

  .form-inline {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .button-section {
    margin-top: 10px;
    margin-bottom: 10px;
  }
